<template>
    <!-- <div class="container">
        <h2>All Posts</h2>
        <div class="posts">
            <div v-for="medium in wpPosts" :key="medium.id" class="post-card">
                <lightgallery :settings="{ speed: 500, plugins: plugins, mode: 'lg-fade' }" :onInit="onInit"
                    :onBeforeSlide="onBeforeSlide">
{{ medium.link }}
                    <a href="https://salonurodyilona.pl/images/sampledata/galeria/2miejsce/2.jpg" title="2.jpg" class=""  target="_blank">
                        <img src="https://salonurodyilona.pl/plugins/content/admirorgallery/admirorgallery/thumbs/galeria/2miejsce/2.jpg" alt="2.jpg" class="ag_imageThumb"></a>
                    <a  class="gallery-item"
                        :data-src="medium.link"
                        data-sub-html="<p> Location - </p>">
                        <img class="img-responsive"
                            :src="medium.link" />
                    </a>
                   

                    {{ medium.content }}
                    <li v-for="med in medium" :key="med.id" ><div v-if="med.alt_text=='7DDD77'"><img :src="med.source_url"><br>{{med.alt_text}}>>>{{med.id}}_ {{med.slug}}</div></li>
                </lightgallery>
                <Fancybox :options="{
                    Carousel: {
                        infinite: false,
                    },
                }">
                    
                </Fancybox>
            </div>
        </div>
    </div> -->
    <!-- <div v-for="medium in wpPosts" :key="medium.id"> -->
    <!-- {{ medium.source_url }} -->
    <lightgallery :settings="{ speed: 500, plugins: plugins }" :onInit="onInit" :onBeforeSlide="onBeforeSlide">
        <a  class="gallery-item"
            :data-src="medium.source_url"
            data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@katherine_xx11' >Katherine Gu </a></h4><p> For all those years we were alone and helpless.</p>"
            v-for="medium in wpPosts" :key="medium.id" >
            <img class="img-responsive"
            :src="medium.source_url" /> 
        </a>
        <a data-lg-size="1400-1400" class="gallery-item"
            data-src="https://images.unsplash.com/photo-1584592740039-cddf0671f3d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
            data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@katherine_xx11' >Katherine Gu </a></h4><p> For all those years we were alone and helpless.</p>">
            <img class="img-responsive"
                src="https://images.unsplash.com/photo-1584592740039-cddf0671f3d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80" />
        </a>
    </lightgallery>
    <!-- </div> -->
</template>
<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.2-beta.1/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.2-beta.1/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.2-beta.1/css/lg-thumbnail.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.2-beta.1/css/lg-video.css'); 
</style>
<script>
// import Fancybox from './Fancybox.vue';
import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';


export default {
    name: 'Posts',
    props: {
        wpPosts: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        plugins: [lgZoom],
    }),
    components: {
        Lightgallery
    }
};
</script>