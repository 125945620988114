<template>
  <div id="app">
    <Posts :wpPosts="posts" />
  </div>
</template>

<script>
import Posts from './components/Posts.vue';
import { ref, onBeforeMount } from 'vue';
export default {
  name: 'App',
  components: {
    Posts,
  },
  setup() {
    const posts = ref([]);

    onBeforeMount(() => {
      const fetchPosts = async () => {
        const res = await fetch(
          // "http://localhost/wp-json/wp/v2/media?slug=image021",
          //http://localhost/wp-json/wp/v2/media?search=7777
          "https://testvu.eu/wordpress/wp-json/wp/v2/media",
          // "http://localhost/wp-json/wp/v2/media",
          // {
          //   headers: {
          //     'Accept': 'application/json, text/plain, */*',
          //     'Content-Type': 'application/json',
          //     'title':'7777'
          //   },
          // }
          // "http://localhost/wp-json/wp/v2/media?date=%222024-09-06T14:21:53%22"
          // "http://localhost/wp-json/wp/v2/media/alt_text='333'"
        );
        const data = await res.json();
        posts.value = data;
      };
      fetchPosts();
    });

    return {
      posts,
    };
  },
};
</script>

<!-- <style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css');
body {
  margin: 0;
}
.gallery-item {
  margin: 5px;
}
</style> -->
